import React from "react";
import { Modal, Backdrop, Box, Fade, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalContent = ({ open, handleClose, handleNextToRowInput, handleModelSelect, selectedModel, currentStep }) => {
  // Function to handle the "Next" button click
  const handleNextClick = () => {
    if (selectedModel) {
      handleNextToRowInput(); // Call handleNextToRowInput function
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            height: 550,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", top: 5, right: 5 }}
          >
            <CloseIcon />
          </IconButton>
          {/* Modal content */}
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginRight: "67px",
            }}
          >
            <span className="filledCircle">1</span>
            <span style={{ color: currentStep === 1 ? "#000" : "#999" }}>
              Name of Model
            </span>
            <span
              style={{
                margin: "0px 10px",
                color: currentStep === 2 ? "#000" : "#999",
              }}
            >
              ---------------------
            </span>
            <span
              className="filledCircle"
              style={{
                backgroundColor: "rgb(244, 239, 239)",
                color: "gray",
                border: "rgb(160, 160, 160)",
              }}
            >
              2
            </span>
            <span style={{ color: currentStep === 2 ? "#000" : "#999" }}>
              No of Rows
            </span>
          </div>

          <hr />
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "0px",
            }}
          >
            Select Model
          </Typography>

          <hr />
          <div style={{ marginTop: "40px" }}>
            <Button
              variant="contained"
              onClick={() => handleModelSelect("CTGAN")}
              style={{
                backgroundColor: selectedModel === "CTGAN" ? "#007bff" : null,
                color: selectedModel === "CTGAN" ? "#fff" : null,
                width: "30%", // Set width to 50% for half width buttons
              }}
            >
              CTGAN
            </Button>
            <Button
              variant="contained"
              onClick={() => handleModelSelect("Model 2")}
              style={{
                marginLeft: "10px",
                backgroundColor:
                  selectedModel === "Model 2" ? "#007bff" : null,
                color: selectedModel === "Model 2" ? "#fff" : null,
                width: "30%", // Set width to 50% for half width buttons
              }}
            >
              Model 2
            </Button>
          </div>

          <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", width: "100%", padding: "0 40px" }}>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{ marginRight: "10px" }}
            >
              Go Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNextClick}
              disabled={!selectedModel} // Disable the button if no model is selected
            >
              Next
            </Button>
          </div>
        </Box>
        {/* Additional modal content */}
      </Fade>
    </Modal>
  );
};

export default ModalContent;
