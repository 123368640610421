import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ColumnSelectionModal from "./ColumnSelectionModal";
import ModelSelectionModal from "./ModalContent";
import RowInputModal from "./RowInputModal";
import "../views/Home/style.css";

const GenerateData = () => {
  const location = useLocation();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [openColumnModal, setOpenColumnModal] = useState(false);
  const [openModelModal, setOpenModelModal] = useState(false);
  const [openRowInputModal, setOpenRowInputModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState("");
  const [numRows, setNumRows] = useState(10000); // Default value
  const availableColumns = location.state?.columns || [];

  useEffect(() => {
    setSelectedColumns(availableColumns);
    setOpenColumnModal(true);
  }, [availableColumns]);

  const handleColumnSelect = (selectedColumn) => {
    setSelectedColumns((prevSelectedColumns) => {
      if (prevSelectedColumns.includes(selectedColumn)) {
        return prevSelectedColumns.filter((col) => col !== selectedColumn);
      } else {
        return [...prevSelectedColumns, selectedColumn];
      }
    });
  };

  const handleNextToModelSelect = () => {
    setOpenModelModal(true);
  };

  const BackButton = () => {
    setOpenRowInputModal(false); 
    setOpenModelModal(true); 
  };
  
  const handleNextToRowInput = () => {
    setOpenModelModal(false);
    setOpenRowInputModal(true);
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
  };

  const handleGenerateData = () => {
    window.history.back();
  };

  const handleCloseColumnModal = () => {
    setOpenColumnModal(false);
  };

  const handleCloseModelModal = () => {
    setOpenModelModal(false);
  };

  const handleCloseRowInputModal = () => {
    setOpenRowInputModal(false);
  };

  return (
    <div style={{ maxWidth: "600px", margin: "60px auto", padding: "50px" }}>
      <ColumnSelectionModal
        open={openColumnModal}
        handleClose={handleCloseColumnModal}
        columns={availableColumns}
        handleNext={handleNextToModelSelect}
        handleColumnSelect={handleColumnSelect}
        selectedColumns={selectedColumns}
      />

      <ModelSelectionModal
        open={openModelModal}
        handleClose={handleCloseModelModal}
        handleNextToRowInput={handleNextToRowInput}
        handleModelSelect={handleModelSelect}
        selectedModel={selectedModel}
      />

      <RowInputModal
        open={openRowInputModal}
        handleClose={handleCloseRowInputModal}
        numRows={numRows}
        BackButton={BackButton}
        setNumRows={setNumRows}
        handleGenerateData={handleGenerateData}
      />
    </div>
  );
};

export default GenerateData;
