import React, { useState,useEffect } from 'react';

import Parse from 'parse';
import swal from 'sweetalert';
import "../../App.css";
import { useNavigate ,Link} from 'react-router-dom';
import {initializeParse}  from "../../services/parseConfig"

// initializeParse();

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // initializeParse();
    checkCurrentUser();
  }, []);

  Parse.initialize("myAppId", "YOUR_JAVASCRIPT_KEY");
  Parse.serverURL = "https://api.aiensured.com/parse";
  

  const checkCurrentUser = () => {
    const currentUser = Parse.User.current();
    if (currentUser) {
      setLoggedInUser(currentUser);
    }
  };

  const handleLogin = async () => {
    try {
      const user = await Parse.User.logIn(email, password);

      if (user) {
        setLoggedInUser(user);
        swal({
          title: "Success!",
          text: "Login Successful!",
          icon: "success",
          button: "Ok",
        });
        navigate("/");
      } else {
        swal({
          title: "Login Failed!",
          text: "Username or Password are not matched",
          icon: "error",
          button: "Ok",
        });
      }
    } catch (error) {
      swal({
        title: "Login Failed!",
        text: "Username or Password are not matched",
        icon: "error",
        button: "Ok",
      });
      console.error("Error logging in:", error.message);
    }
  };

  return (
    <div className="container col-sm-3 col-lg-4 mx-auto logintable">
      <div
        style={{
          boxShadow: " 0px 0px 5px 0px rgba(0,0,0,0.75)",
          borderRadius: "10px",
          padding: "30px",
          marginTop: "180px",
          marginBottom: "40px",
        }}
      >
        <h2 className="text-center mb-6">LOGIN</h2>
        <div className="col-md-8 mx-auto my-4">
          <input
            className="my-3 p-3 form-control fs-5 form_input"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="my-3 p-3 form-control fs-5 form_input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="btn btn-danger w-100 p-3"
            id="submit_button"
            onClick={handleLogin}
          >
            Login
          </button>

          <Link to="/forgotpassword">
            <button className="btn btn-link">Forgot Password?</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
