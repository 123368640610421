import React from "react";
import {
  Modal,
  Backdrop,
  Box,
  Fade,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const RowInputModal = ({
  open,
  handleClose,
  numRows,
  setNumRows,
  handleGenerateData,
  BackButton,
  currentStep,
}) => {
  const handleSuggestedInput = (value) => {
    setNumRows(value);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            height: 550,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", top: 5, right: 5 }}
          >
            <CloseIcon />
          </IconButton>

          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <span
              className="filledCircle"
              style={{
                backgroundColor: "rgb(244, 239, 239)",
                color: "gray",
                border: "rgb(160, 160, 160)",
              }}
            >
              1
            </span>
            <span style={{ color: currentStep === 2 ? "#000" : "#999" }}>
              Name of Model
            </span>
            <span
              style={{
                margin: "0px 10px",
                color: currentStep === 2 ? "#000" : "#999",
              }}
            >
              ---------------------
            </span>
            <span className="filledCircle">2</span>
            <span style={{ color: currentStep === 1 ? "#000" : "#999" }}>
              Enter No of Rows
            </span>
          </div>

          <hr></hr>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "0px",
              marginTop: "30px",
            }}
          >
            Enter Number of datapoints to generate (10k-10 million)
          </Typography>

          <hr></hr>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <input
              type="number"
              value={numRows}
              onChange={(e) => setNumRows(e.target.value)}
              style={{
                marginTop: "20px",
                width: "100%",
                padding: "10px",
                marginBottom: "20px",
              }}
              placeholder="Enter number of rows"
            />
          </div>

          {/* Suggested input buttons */}
          <div style={{ marginTop: "20px" ,textAlign:"center"}}>
            <Button
              variant="outlined"
              onClick={() => handleSuggestedInput(1000)}
              style={{ marginRight: "10px" }}
            >
              1,000
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleSuggestedInput(10000)}
              style={{ marginRight: "10px" }}
            >
              10,000
            </Button>
            <Button
              variant="outlined"
              style={{ marginRight: "10px" }}

              onClick={() => handleSuggestedInput(50000)}
            >
              50,000
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleSuggestedInput(1000000)}
            >
             10,00,000
            </Button>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              padding: "0 40px",
            }}
          >
            <Button
              variant="contained"
              onClick={BackButton} // Go back button functionality
              style={{ marginRight: "10px" }}
            >
              Go Back
            </Button>
            <Button variant="contained" onClick={handleGenerateData}>
              Generate
            </Button>
          </div>
        </Box>
        {/* Additional modal content */}
      </Fade>
    </Modal>
  );
};

export default RowInputModal;
