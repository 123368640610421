import React from "react";
import { Modal, Backdrop, Box, Fade, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ColumnSelectionModal = ({
  open,
  handleClose,
  handleBack,
  columns,
  handleColumnSelect,
  selectedColumns,
  handleNext,
}) => {
  return (
    <Modal
      aria-labelledby="column-selection-modal-title"
      aria-describedby="column-selection-modal-description"
      open={open}
      back={handleBack}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            height: 550,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", top: 5, right: 5 }}
          >
            <CloseIcon />
          </IconButton>
          {/* <div style={{ textAlign: "center", marginTop: "10px", marginRight: "67px" }}> */}
          <h2 style={{ fontSize: "1.5em", marginBottom: "27px" }}>
            Choose columns to generate synthetic data
          </h2>
          {columns.map((column) => (
            <div
              key={column}
              className="form-check"
              style={{ marginBottom: "33px" }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                id={column}
                checked={selectedColumns.includes(column)}
                onChange={() => handleColumnSelect(column)}
              />
              <label className="form-check-label" htmlFor={column}>
                {column}
              </label>
            </div>
          ))}
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              onClick={() => window.history.back()}
              style={{ marginRight: "10px" }}
            >
              Go Back
            </Button>
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          </div>
          {/* </div> */}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ColumnSelectionModal;
