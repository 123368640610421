import React, {useEffect, lazy, Suspense } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginForm from './views/Home/Login';
import GenerateData from "component/GenerateData";
import Forgotpwd from "./views/Home/Forgotpwd";
import { initializeParse } from './services/parseConfig';
initializeParse();
const LazyLoadedComponent = lazy(() => import("./views/Home"));
const theme = createTheme({
  palette: {
    primary: {
      main: "#a03c64",
    },
  },
});

function App() {
  useEffect(() => {
    initializeParse();
  }
  , []);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Suspense
            fallback={
              <div className="spinner-container">
                <div className="spinner-border custom-spinner" role="status"></div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<LazyLoadedComponent />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/generatedata" element={<GenerateData />}/>
              <Route path="/forgotpassword" element={<Forgotpwd />} />

            </Routes>
          </Suspense>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
